.about {
	background-image: url('./images/about-bg.png');
	background-size: cover;
	width: 100%;
	height: 401px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about_block_text {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 35px;
}

.about_title {
	color: white;
	font-size: 52px;
	font-weight: bold;
}

.about_two_block {
	padding: 68px 0 87px 0;
	display: flex;
	flex-direction: column;
	gap: 77px;

	.card {
		display: flex;
		justify-content: center;
		gap: 250px;
		padding-bottom: 77px;
		.card-con1 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 20px 0;
			max-width: 449px;
			h2 {
				font-size: 24px;
				font-weight: bold;
				color: white;
				padding-bottom: 20px;
			}
			p {
				font-size: 18px;
				font-weight: 200;
				color: white;
			}
		}
		.card-con2 {
			padding: 20px 0;
			display: flex;

			img {
				max-width: 449px;
				height: auto;
				background-size: cover;
				background-position: center;
			}
		}
	}
}

.about_tree_block {
	background-image: url('./images/bg2.png');
	background-size: cover;
	width: 100%;
	height: 401px;
	display: flex;
	justify-content: center;
	align-items: center;
	.container {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-transform: uppercase;
		color: white;
		font-size: 24px;
		font-weight: bold;
		gap: 30px;
		h2 {
			font-size: 24px;
			text-align: center;
			padding-bottom: 37px;
		}
		div {
			width: 100%;
			display: flex;
			align-items: start;
			justify-content: space-between;

			div {
				width: 350px;
				display: flex;
				flex-direction: column;

				h2 {
					font-size: 24px;
					padding-bottom: 17px;
				}
				p {
					font-weight: 200;
					font-size: 18px;
				}
			}
		}
		button {
			padding: 10px 55px;
			background: none;
			border: 4px solid #004ce1;
			font-size: 18px;
			font-weight: bold;
			border-radius: 18px;
			color: white;
			text-transform: uppercase;
			cursor: pointer;
		}
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.about_two_block {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			gap: 100px;
			padding-bottom: 50px;
		}
	}
	.about_tree_block {
		.container {
			font-size: 20px;
			h2 {
				font-size: 20px;
			}
			div {
				div {
					width: 300px;
					h2 {
						font-size: 20px;
						padding-bottom: 17px;
					}
					p {
						font-size: 16px;
					}
				}
			}
			button {
				padding: 10px 55px;
				font-size: 16px;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.about {
		.about_block_text {
			.about_title {
				font-size: 46px;
			}
		}
	}
	.about_two_block {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			gap: 100px;
			padding-bottom: 10px;
			.card-con2 {
				padding: 20px 0;
				display: flex;

				img {
					padding: 20px 0 20px 0;
					max-width: 300px;
					height: auto;
				}
			}
		}
	}
	.about_tree_block {
		.container {
			font-size: 18px;
			h2 {
				font-size: 18px;
			}
			div {
				gap: 30px;
				div {
					width: 300px;
					h2 {
						font-size: 17px;
						padding-bottom: 17px;
					}
					p {
						font-size: 16px;
					}
				}
			}
			button {
				padding: 10px 55px;
				font-size: 16px;
			}
		}
	}
}

@media (min-width: 425px) and (max-width: 767px) {
	.about {
		.about_block_text {
			.about_title {
				font-size: 40px;
			}
		}
	}
	.about_two_block {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			flex-direction: column;
			gap: 0;
			padding-bottom: 10px;
			.card-con2 {
				img {
					padding: 0 0 0 0;
					max-width: 400px;
					height: auto;
				}
			}
		}
		.cont2 {
			.card-con2 {
				order: 2;
			}
			.card-con1 {
				order: 1;
			}
		}
	}
	.about_tree_block {
		height: 650px;
		.container {
			font-size: 18px;
			h2 {
				font-size: 18px;
				padding-bottom: 5px;
			}
			div {
				gap: 20px;
				flex-direction: column;
				div {
					width: 100%;
					h2 {
						font-size: 17px;
						padding-bottom: 17px;
					}
					p {
						font-size: 15px;
					}
				}
			}
			button {
				padding: 5px 25px;
				font-size: 15px;
			}
		}
	}
}

@media (min-width: 375px) and (max-width: 424px) {
	.about {
		.about_block_text {
			.about_title {
				font-size: 40px;
			}
		}
	}
	.about_two_block {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			flex-direction: column;
			gap: 0;
			padding-bottom: 10px;
			.card-con2 {
				img {
					padding: 0 0 0 0;
					max-width: 350px;
					height: auto;
				}
			}
			.card-con1 {
				padding: 20px 0;
				max-width: 449px;
				h2 {
					font-size: 18px;
					padding-bottom: 10px;
				}
				p {
					font-size: 15px;
				}
			}
		}
		.cont2 {
			.card-con2 {
				order: 2;
			}
			.card-con1 {
				order: 1;
			}
		}
	}
	.about_tree_block {
		height: 600px;
		.container {
			font-size: 16px;
			h2 {
				font-size: 16px;
				padding-bottom: 5px;
			}
			div {
				gap: 20px;
				flex-direction: column;
				div {
					width: 100%;
					h2 {
						font-size: 16px;
						padding-bottom: 0;
					}
					p {
						font-size: 14px;
					}
				}
			}
			button {
				padding: 5px 25px;
				font-size: 15px;
			}
		}
	}
}

@media (min-width: 320px) and (max-width: 374px) {
	.about {
		.about_block_text {
			.about_title {
				font-size: 36px;
			}
		}
	}
	.about_two_block {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			flex-direction: column;
			gap: 0;
			padding-bottom: 10px;
			.card-con2 {
				img {
					padding: 0 0 0 0;
					max-width: 300px;
					height: auto;
				}
			}
			.card-con1 {
				padding: 20px 0;
				max-width: 449px;
				h2 {
					font-size: 18px;
					padding-bottom: 10px;
				}
				p {
					font-size: 15px;
				}
			}
		}
		.cont2 {
			.card-con2 {
				order: 2;
			}
			.card-con1 {
				order: 1;
			}
		}
	}
	.about_tree_block {
		height: 550px;
		.container {
			font-size: 16px;
			h2 {
				font-size: 15px;
				padding-bottom: 5px;
			}
			div {
				gap: 20px;
				flex-direction: column;
				div {
					width: 100%;
					h2 {
						font-size: 15px;
						padding-bottom: 0;
					}
					p {
						font-size: 12px;
					}
				}
			}
			button {
				padding: 5px 25px;
				border: 2px solid #004ce1;
				font-size: 14px;
			}
		}
	}
}
