@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  /* border: 1px solid red; */

}

.container {
  width: 1379px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

body {
  background-color: black;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .container {
    width: 950px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 700px;
    margin: 0 auto;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .container {
    width: 400px;
    margin: 0 auto;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .container {
    width: 350px;
    margin: 0 auto;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .container {
    width: 310px;
    margin: 0 auto;
  }
}