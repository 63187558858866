.about-5 {
	background-image: url('./images/product_bg.png');
	background-size: cover;
	width: 100%;
	height: 401px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about_block_text {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 35px;
}

.about_title {
	color: white;
	font-size: 52px;
	font-weight: bold;
}

.about_block_one {
	background-color: #007aff;
	padding: 58px 0 58px 0;
	color: white;
	h2 {
		font-size: 24px;
		padding-bottom: 20px;
	}
	p {
		font-size: 18px;
	}
}
.about_two_block2 {
	padding: 68px 0 87px 0;
	display: flex;
	flex-direction: column;
	gap: 77px;

	.card {
		display: flex;
		justify-content: center;
		gap: 250px;
		padding-bottom: 77px;
		.card-con1 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 20px 0;
			max-width: 449px;
			h2 {
				font-size: 24px;
				font-weight: bold;
				color: white;
				padding-bottom: 20px;
			}
			p {
				font-size: 18px;
				font-weight: 200;
				color: white;
			}
		}
		.card-con2 {
			padding: 20px 0;
			display: flex;

			img {
				max-width: 449px;
				height: auto;
				background-size: cover;
				background-position: center;
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1439px) {
	.about_two_block2 {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			gap: 100px;
			padding-bottom: 50px;
		}
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.about-5 {
		.about_block_text {
			.about_title {
				font-size: 46px;
			}
		}
	}
	.about_block_one {
		padding: 40px 0 40px 0;
		color: white;
		h2 {
			font-size: 20px;
			padding-bottom: 20px;
		}
		p {
			font-size: 16px;
		}
	}
	.about {
		.about_block_text {
			.about_title {
				font-size: 46px;
			}
		}
	}
	.about_title {
		font-size: 46px;
	}
	.about_two_block2 {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			gap: 100px;
			padding-bottom: 10px;
			.card-con2 {
				padding: 20px 0;
				display: flex;

				img {
					padding: 20px 0 20px 0;
					max-width: 300px;
					height: auto;
				}
			}
		}
	}
}

@media (min-width: 425px) and (max-width: 767px) {
	.about-5 {
		.about_block_text {
			.about_title {
				font-size: 40px;
			}
		}
	}
	.about_block_one {
		padding: 20px 0 20px 0;
		color: white;
		h2 {
			font-size: 18px;
			padding-bottom: 10px;
		}
		p {
			font-size: 14px;
		}
	}
	.about {
		.about_block_text {
			.about_title {
				font-size: 40px;
			}
		}
	}
	.about_two_block2 {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			flex-direction: column;
			gap: 0;
			padding-bottom: 10px;
			align-items: center;
			text-align: center;
			.card-con2 {
				img {
					padding: 0 0 0 0;
					max-width: 100px;
					height: auto;
				}
			}
			.card-con1 {
				padding: 20px 0;
				max-width: 449px;
				h2 {
					font-size: 18px;
					padding-bottom: 10px;
				}
				p {
					font-size: 15px;
				}
			}
		}
		.cont2 {
			.card-con2 {
				order: 2;
			}
			.card-con1 {
				order: 1;
			}
		}
	}
}

@media (min-width: 375px) and (max-width: 424px) {
	.about-5 {
		height: 350px;
		.about_block_text {
			.about_title {
				font-size: 36px;
			}
		}
	}
	.about_block_one {
		padding: 20px 0 20px 0;
		color: white;
		h2 {
			font-size: 16px;
			padding-bottom: 10px;
		}
		p {
			font-size: 12px;
		}
	}
	.about {
		.about_block_text {
			.about_title {
				font-size: 40px;
			}
		}
	}
	.about_two_block2 {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 0;
			padding-bottom: 10px;
			.card-con2 {
				img {
					padding: 0 0 0 0;
					max-width: 100px;
					height: auto;
				}
			}
			.card-con1 {
				padding: 20px 0;
				h2 {
					font-size: 18px;
					padding-bottom: 10px;
				}
				p {
					font-size: 15px;
				}
			}
		}
		.cont2 {
			.card-con2 {
				order: 2;
			}
			.card-con1 {
				order: 1;
			}
		}
	}
}

@media (min-width: 320px) and (max-width: 374px) {
	.about-5 {
		height: 300px;
		
		.about_block_text {
			.about_title {
				font-size: 36px;
			}
		}
	}
	.about_block_one {
		padding: 20px 0 20px 0;
		color: white;
		h2 {
			font-size: 16px;
			padding-bottom: 10px;
		}
		p {
			font-size: 12px;
		}
	}
	.about {
		.about_block_text {
			.about_title {
				font-size: 36px;
			}
		}
	}
	.about_two_block2 {
		padding: 50px 0 87px 0;
		gap: 50px;

		.card {
			flex-direction: column;
			gap: 0;
			align-items: center;
			text-align: center;
			padding-bottom: 10px;
			.card-con2 {
				img {
					padding: 0 0 0 0;
					max-width: 100px;
					height: auto;
				}
			}
			.card-con1 {
				padding: 20px 0;
				h2 {
					font-size: 18px;
					padding-bottom: 10px;
				}
				p {
					font-size: 15px;
				}
			}
		}
		.cont2 {
			.card-con2 {
				order: 2;
			}
			.card-con1 {
				order: 1;
			}
		}
	}
}
